import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
/* import iconstopwatch from "../images/icon-stopwatch.svg"; */
/* import iconlightbulb from "../images/icon-light-bulb.svg"; */
/* import icondata from "../images/icon-data.svg"; */
/* import iconroadmap from "../images/icon-roadmap.svg"; */
/* import heroimage from "../images/digital-media-audit-hero.jpg"; */
/* import audithelpdevice from "../images/digital-media-audit-help-device.png"; */
/* import audithelpscorecard from "../images/digital-media-audit-help-scorecard.png"; */

import iconuxanalysis from "../images/icon-ux-analysis.svg";

import iconColouredCrmAndLoyalty		from "../images/icon-coloured-crm-and-loyalty.svg";
import iconColouredConversionRateOptimisation	from "../images/icon-coloured-conversion-rate-optimisation.svg";
import iconColouredCreativeAndContentProduction	from "../images/icon-coloured-creative-and-content-production.svg";
import iconColouredWebAndAppDesign		from "../images/icon-coloured-web-and-app-design.svg";
import iconColouredUxCxDesign			from "../images/icon-coloured-ux-cx-design.svg";
import iconColouredSocial			from "../images/icon-coloured-social.svg";
import iconColouredOrganicSearch		from "../images/icon-coloured-organic-search.svg";
import iconColouredDigitalMedia			from "../images/icon-coloured-digital-media.svg";
import iconColouredDigitalStrategy		from "../images/icon-coloured-digital-strategy.svg"

import "../scss/coronavirus.scss";
import "../scss/auditHeroSection.scss";

const AuditDigitalMedia = ({ data }) => {
  const metadescription = "Next&Co are an independent SEO Agency specialising in audits, visit our site to find out your search and market trends and how to build a roadmap to success.";
  const metatitle = "Get some help accessing your grant - Next&Co";
  const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
	<Helmet>
	    <title>{metatitle}</title>
	    <meta name="description" content={metadescription}/>
	    <meta name="title" content={metatitle} />
	    <meta property="og:type" content="website" />
	    <meta property="og:url" content={metaurl} />
	    <meta property="og:title" content={metatitle} />
	    <meta property="og:description" content={metadescription} />
	    <meta property="og:image" content="facebook.png" />
	    <meta property="twitter:url" content={metaurl} />
	    <meta property="twitter:title" content={metatitle} />
	    <meta property="twitter:description" content={metadescription} />
	    <meta property="twitter:image" content="twitter.png" />
	</Helmet>
	<div id="coronavirus">
	    <Layout>
		<section className="audit-hero-section">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="col-one">
				    <h5>
					Receive A Grant Up To $5,000 For Digital Services
				    </h5>
				    <h6>
					What are Covid-19 community response grants?
				    </h6>
				    <p>
					The City Of Melbourne are offering $5 million in grants for local businesses to invest in online and e-commerce capabilities. The quick response grants for business and non-profit organisations include up to $5000 for investing in online and e-commerce activities.
				    </p>

				    <h6>
					Who is eligible?
				    </h6>
				    <p>
					To be eligible to apply applicants must meet the following criteria:
				    </p>
				    <ul>
					<li>
					    — be located within the City of Melbourne municipality
					</li>
					<li>
					    — have an Australian Business Number (ABN)
					</li>
					<li>
					    — be a small to medium sized business
					</li>
					<li>
					    — able to demonstrate how the grant will help support your business in response to the impact of COVID-19
					</li>
				    </ul>
				    <h6>
					How can Next&Co help?
				    </h6>
				    <p>
					Next&Co can assist with completing the application of your grant and use your funding on the following services:
				    </p>
				</div>
				<section id="digital-strategy-colored-icons" className="show-mobile">
				    <div className="outer-container">
					<div className="container-max-width">
					    <div className="inner-container">
						<div className="row">
						    <div className="col">
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredDigitalStrategy}/>						
							    </div>
							    <h6>
								Digital Strategy
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredWebAndAppDesign}/>
							    </div>
							    <h6>
								Web & App Design
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredDigitalMedia}/>
							    </div>
							    <h6>
								Digital Media
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredCreativeAndContentProduction}/>
							    </div>
							    <h6>
								Creative & Content Production
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredOrganicSearch}/>
							    </div>
							    <h6>
								Organic Search
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredConversionRateOptimisation}/>
							    </div>
							    <h6>
								Conversion Rate Optimisation
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredSocial}/>
							    </div>
							    <h6>
								Social
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredCrmAndLoyalty}/>
							    </div>
							    <h6>
								CRM & Loyalty
							    </h6>
							</div>
							<div className="row">
							    <div className="image-container">
								<img alt="" src={iconColouredUxCxDesign}/>
							    </div>
							    <h6>
								UX/CX Design
							    </h6>
							</div>
						    </div>
						</div>	
					    </div>
					</div>			    
				    </div>
				</section>
				<div className="col-two">
				    <div className="form-container">
					<h2>Get some help accessing your grant</h2>
					<h6>
					    Call us on <a href="tel:1300191950">1300 191 950</a> or fill out the form below
					</h6>
					{/* <h1>{this.props.formSubmitUrl}</h1> */}
					<form
					  name="coronavirus-contact"
					  method="post"
					  action="/grant-thankyou/"
					  data-netlify="true"
					  data-netlify-honeypot="bot-field"
					>
					    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					    <input type="hidden" name="form-name" value="coronavirus-contact" />
					    <p hidden>
						<label>
						    Don’t fill this out:{" "}
						    <input name="bot-field"/>
						</label>
					    </p>
					    <p></p>
					    <p>
						<label>
						    Your Name
						    <input
						      type="text"
						      required
						      name="your-name"
						      placeholder="Your name"
						    />
						</label>
					    </p>
					    <p>
						<label>
						    URL
						    <input
						      type="text"
						      required
						      name="website-url"
						      placeholder="Website URL"
						    />
						</label>
					    </p>
					    <p>
						<label>
						    Email
						    <input
						      type="email"
						      required
						      name="email"
						      placeholder="Your email"
						    />
						</label>
					    </p>
					    <p>
						<label>
						    Phone
						    <input
						      type="tel"
						      name="phone"
						      required
						      placeholder="Your phone"
						    />
						</label>
					    </p>
					    <p>
						<label>
						    Message
						    <textarea
						      name="message"
						      id="message"
						      required
						      placeholder="Your message"
						      style={{resize: `none`, width: `100%`}}
						    />

						</label>
					    </p>
					    <p>
						<button className="btn btn-invert" type="submit">
						    Get in touch
						</button>
					    </p>
					</form>
				    </div>
				</div>
			    </div>
			</div>
		    </div>
		</section>
		<section id="digital-strategy-colored-icons" className="show-computer">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="row">
				    <div className="col">
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredDigitalStrategy}/>						
					    </div>
					    <h6>
						Digital Strategy
					    </h6>
					</div>
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredDigitalMedia}/>
					    </div>
					    <h6>
						Digital Media
					    </h6>
					</div>
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredOrganicSearch}/>
					    </div>
					    <h6>
						Organic Search
					    </h6>
					</div>
				    </div>
				    <div className="col">
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredSocial}/>
					    </div>
					    <h6>
						Social
					    </h6>
					</div>
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredUxCxDesign}/>
					    </div>
					    <h6>
						UX/CX Design
					    </h6>
					    
					</div>
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredWebAndAppDesign}/>
					    </div>
					    <h6>
						Web & App Design
					    </h6>
					    
					</div>
				    </div>
				    <div className="col">
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredCreativeAndContentProduction}/>
					    </div>
					    <h6>
						Creative & Content Production
					    </h6>
					    
					</div>
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredConversionRateOptimisation}/>
					    </div>
					    <h6>
						Conversion Rate Optimisation
					    </h6>
					    
					</div>
					<div className="row">
					    <div className="image-container">
						<img alt="" src={iconColouredCrmAndLoyalty}/>
					    </div>
					    <h6>
						CRM & Loyalty
					    </h6>
					</div>
				    </div>
				</div>	
			    </div>
			</div>			    
		    </div>
		</section>
		<section id="digital-strategy-contact-row">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="row">
				    <div className="col">
					<h6>
					    Email
					</h6>
					<p>
					    <a className="email"href="mailto:enquiries@nextandco.com.au?subject=Next and Co grant enquiry">enquiries@nextandco.com.au</a>
					</p>
				    </div>
				    <div className="col">
					<h6>
					    Location
					</h6>
					<p>
					    <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Level+7%2F22+Albert+Rd,+South+Melbourne+VIC+3205/@-37.8331896,144.9691753,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad642a81209c7c9:0xe05ea617da3ea273!8m2!3d-37.8331939!4d144.971364">	Level 7/22 Albert Rd, South Melbourne
						<br/> VIC 3205 Melbourne.
					    </a>
					</p>
				    </div>
				    <div className="col">
					<h6>
					    Tel
					</h6>
					<p>
					    <a href="tel:1300191950">1300 191 950</a>
					</p>
				    </div>
				</div>
			    </div>
			</div>
		    </div>
		</section>
	    </Layout>
	</div>
    </>
  )
}

export default AuditDigitalMedia
